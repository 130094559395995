/**/
article.dropdown {
    margin: $component-margin1;
    background-color: $grey1;
    border-radius: $border-radius1;
    position: relative;
    @include mobile-and-tablette {
        margin: $component-margin-mobile1;
        width: calc(100% - $component-margin-mobile1 * 2);
    }
    > div.dropdown__topbar {
        position: relative;
        div.dropdown__topbar-background {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $red1;
            border-radius: $border-radius1;
        }
        div.dropdown__informations {
            color: white;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: $border-radius1;
            > * {
                z-index: 2;
            }

            > img.dropdown__icon {
                height: 32px;
                width: 32px;
                &--closed {
                    transform: rotateX(180deg);
                }
            }
        }
        &:hover {
            cursor: pointer;
            > div.dropdown__topbar-background {
                filter: brightness(0.9);
                transition: all 0.2s ease;
            }
        }
    }
    > div.dropdown__content {
        position: relative;
        z-index: 100;
        border-radius: $border-radius1;
        padding: 10px 20px 20px 20px;
        color: $red1;
    }
}

.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: $component-margin1;
    border-radius: $border-radius2;

    @include mobile-and-tablette {
        margin: $component-margin-mobile1;
    }
    img.carousel__image {
        width: 100%;
        aspect-ratio: 3.5 / 1;
        height: auto;
        display: block;
        border-radius: $border-radius2;
    }

    img.carousel__button {
        position: absolute;
        top: 50%;
        padding: 10px 10px;
        height: 120px;
        width: auto;
        aspect-ratio: 1 / 1;
        color: white;
        border: none;
        opacity: 0.8;
        &--previous {
            left: 0px;
            transform: translateY(-50%) rotate(-90deg);
            &:hover {
                cursor: pointer;
                transform: translateY(-50%) rotate(-90deg) scale(1.2);
                opacity: 1;
                transition: all 0.2s;
            }
        }

        &--next {
            right: 0px;
            transform: translateY(-50%) rotate(90deg);
            &:hover {
                cursor: pointer;
                transform: translateY(-50%) rotate(90deg) scale(1.2);
                opacity: 1;
                transition: all 0.2s;
            }
        }
    }
    .carousel__index {
        position: absolute;
        bottom: 2%;
        color: white;
        font-size: 18px;
        font-weight: 500;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.536));
    }
}

div.card {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc(33.3% - 4%);
    aspect-ratio: 1 / 1;
    padding: 0;
    border-radius: $border-radius1;
    margin: 2%;

    > .card__link {
        display: inline-block;
        position: relative;
        border-radius: $border-radius1;
        height: 100%;
        width: 100%;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: $border-radius1;
            height: 100%;
            width: 100%;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(0, 0, 0, 0.5) 100%
            );
        }
        &:hover::after {
            opacity: 0.4;
            transition: all 0.2s ease;
        }

        > img.card__image {
            position: relative;
            z-index: -1;
            border-radius: $border-radius1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    > h2.card__title {
        padding: 0;
        margin: 20px;
        z-index: 1;
        position: absolute;
        text-align: left;
        left: 0%;
        bottom: 0%;
        color: white;
        font-weight: 400;
        font-size: 18px;
        user-select: none;
        pointer-events: none;
        @include mobile-and-tablette {
            width: 50%;
        }
    }
    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease;
        filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.186));
        > h2.card__title {
            filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.318));
            transition: all 0.2s ease;
        }
    }
    @include mobile-and-tablette {
        width: 100%;
        aspect-ratio: 4 / 3;
        margin: 20px 0;
    }
}
