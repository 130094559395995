/**/

section.cards-list {
    background-color: $grey1;
    border-radius: $border-radius2;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: row wrap;
    @include from-desktop {
        margin: $component-margin2;
    }

    @include mobile-and-tablette {
        margin: $component-margin-mobile1;
        justify-content: flex-start;
        background: none;
        padding: 0;
    }
}

div.dropdowns-list {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    width: 100%;
    @include from-desktop {
        > .dropdown {
            width: 100%;
        }
    }

    @include mobile-and-tablette {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}
