@use 'sass:color';
/**/

$mobile: 570;
$tablette: 960;
$desktop: 1460;
$tv: 'taille XL';
$breakpoints: (
    mobile: $mobile,
    tablette: $tablette,
    desktop: $desktop,
);

$black: black;
$black1: color.adjust($black, $lightness: 10%);
$black2: color.adjust($black, $lightness: 20%);
$black3: color.adjust($black, $lightness: 30%);
$black5: color.adjust($black, $lightness: 50%);

$black: black;
$white: white;
$dark: #eaeaea;
$dark1: #f6f6f6;
$dark2: color.adjust($dark, $lightness: 20%);
$dark3: color.adjust($dark, $lightness: 30%);
$dark5: color.adjust($dark, $lightness: 50%);

$shadow0: 8px 25px 45px -12px rgba(94, 94, 94, 0.62);
$shadow0_: 8px 25px 45px -0px rgba(94, 94, 94, 1);
$shadow1: 8px 8px 45px -12px rgba(94, 94, 94, 0.62);
$shadow2: 0px 5px 30px -10px rgb(94 94 94 / 62%);

$red1: #ff6060;

$grey1: #f7f7f7;

$component-margin1: 30px 80px;
$component-margin2: 20px 80px;
$component-margin-mobile1: 20px;

$border-radius1: 10px;
$border-radius2: 25px;
$border-radius3: 100%;
